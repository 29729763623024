<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Exit Interview Questions</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="70%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  block
                  class="rounded-xl text-none"
                  color="#005f32"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Question
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pa-4
                      text-left
                      style="background: #005f32 !important"
                    >
                      <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Add Question</span
                      >
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pa-5 pt-8>
                      <v-textarea
                        v-model="question"
                        label="Question"
                        :rules="[rules.required]"
                        required
                        outlined
                        dense
                        hide-details
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center text-left px-5 pa-2>
                      <v-text-field
                        v-model="order"
                        label="Order of question"
                        outlined
                        dense
                        type="number"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center text-left px-5 pa-2>
                      <v-checkbox
                        v-model="isRequired"
                        label="Is this question is mandatory?"
                        hide-details
                        color="#005f32"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center text-left px-5 pa-2>
                      <v-select
                        v-model="answerType"
                        label="Choose Answer Type"
                        :rules="[rules.required]"
                        required
                        outlined
                        class="text-capitalize"
                        hide-details
                        :items="answerTypes"
                        item-text="name"
                        item-value="val"
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      text-left
                      px-5
                      pa-2
                      v-if="answerType && answerType != 'text'"
                    >
                      <v-checkbox
                        v-model="isMultiSelect"
                        label="Can there be more than one answer to this question?"
                        hide-details
                        color="#005f32"
                      ></v-checkbox>
                    </v-flex>

                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-5
                      pt-8
                      v-if="answerType && answerType != 'text'"
                    >
                      <span class="kumbhSemibold"
                        >Add options for the question</span
                      >
                      &nbsp;&nbsp;&nbsp;
                      <v-btn dark fab @click="addNewOp()" color="#005f32">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-flex>

                    <v-flex
                      xs12
                      align-self-center
                      v-if="options.length > 0"
                      pa-2
                      px-5
                    >
                      <v-layout wrap v-for="(sub, s) in options" :key="s">
                        <v-flex xs11 align-self-center text-left pa-2>
                          <v-text-field
                            v-model="sub.optionvalue"
                            label="Enter option"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs1 align-self-center text-left pa-2>
                          <v-btn
                            color="#000"
                            @click.prevent="options.splice(s, 1)"
                            dark
                            fab
                            small
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="validateQn()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12 align-self-center fill-height pa-4>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Question</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in questions"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.question"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.question.slice(0, 50) }}</span
                      >
                    </td>

                    <td>
                      <v-layout wrap justify-center>
                        <v-flex xs6 sm4 align-self-center pa-2>
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                depressed
                                dark
                                title="Delete"
                                v-on="on"
                                v-bind="attrs"
                                color="#005f32"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Delete Confirmation</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-card-title
                                >Are you sure you want to delete this
                                question?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  tile
                                  dark
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                &nbsp;
                                <v-btn
                                  color="#005f32"
                                  outlined
                                  tile
                                  @click="deleteItem(item)"
                                  >Delete</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                        <v-flex sm4 align-self-center pa-2>
                          <v-dialog v-model="item.view" max-width="70%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                title="View"
                                color="#005f32"
                                v-on="on"
                                v-bind="attrs"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Question Details</span
                                  >
                                </v-flex>
                                <v-flex
                                  xs12
                                  align-self-center
                                  class="kumbhRegular"
                                >
                                  <v-layout wrap pa-4>
                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span v-if="item.question">
                                        Question : {{ item.question }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span
                                        class="text-capitalize"
                                        v-if="item.answerType"
                                      >
                                        Answer Type : {{ item.answerType }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-4
                                      v-if="
                                        item.options &&
                                        item.answerType != 'text'
                                      "
                                    >
                                      <span class="kumbhBold"> Options </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      v-if="item.options"
                                    >
                                      <v-layout
                                        wrap
                                        v-if="item.options.length > 0"
                                      >
                                        <v-flex
                                          xs12
                                          pa-1
                                          v-for="(split, s) in item.options"
                                          :key="s"
                                        >
                                          <v-card
                                            outlined
                                            class="rounded-lg pa-3 kumbhMedium"
                                          >
                                            <v-layout
                                              wrap
                                              pa-2
                                              text-left
                                              v-if="split.optionvalue"
                                            >
                                              <v-flex xs6 align-self-center>
                                                <span> {{ s + 1 }}. </span>
                                              </v-flex>
                                              <v-flex
                                                xs6
                                                align-self-center
                                                text-left
                                              >
                                                <span>
                                                  {{ split.optionvalue }}
                                                </span>
                                              </v-flex>
                                            </v-layout>
                                          </v-card>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                        <v-flex xs6 sm4 align-self-center pa-2>
                          <v-btn
                            color="#005f32"
                            small
                            dark
                            icon
                            title="Edit"
                            @click="editcat(item)"
                          >
                            <v-icon>mdi-pen</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="70%">
            <v-card tile>
              <v-layout wrap>
                <v-flex
                  xs12
                  pa-4
                  text-left
                  align-self-center
                  style="background: #005f32 !important"
                >
                  <span
                    class="kumbhBold"
                    style="color: #ffffff; font-size: 20px"
                    >Edit Question</span
                  >
                </v-flex>

                <v-flex xs12 align-self-center text-left pa-5 pt-8>
                  <v-textarea
                    v-model="editingitem.question"
                    label="Question"
                    :rules="[rules.required]"
                    required
                    outlined
                    dense
                    hide-details
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm6 align-self-center text-left px-5 pa-2>
                  <v-text-field
                    v-model="editingitem.order"
                    label="Order of question"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left px-5 pa-2>
                  <v-checkbox
                    v-model="editingitem.isRequired"
                    label="Is this question is mandatory?"
                    hide-details
                    color="#005f32"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left px-5 pa-2>
                  <v-select
                    v-model="editingitem.answerType"
                    label="Choose Answer Type"
                    :rules="[rules.required]"
                    required
                    outlined
                    class="text-capitalize"
                    hide-details
                    :items="answerTypes"
                    item-text="name"
                    item-value="val"
                    dense
                    @change="editingitem.options = []"
                  ></v-select>
                </v-flex>

                <v-flex
                  xs12
                  sm6
                  align-self-center
                  text-left
                  px-5
                  pa-2
                  v-if="
                    editingitem.answerType && editingitem.answerType != 'text'
                  "
                >
                  <v-checkbox
                    v-model="editingitem.isMultiSelect"
                    label="Can there be more than one answer to this question?"
                    hide-details
                    color="#005f32"
                  ></v-checkbox>
                </v-flex>

                <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-5
                  pt-8
                  v-if="
                    editingitem.answerType && editingitem.answerType != 'text'
                  "
                >
                  <span class="kumbhSemibold"
                    >Add options for the question</span
                  >
                  &nbsp;&nbsp;&nbsp;
                  <v-btn dark fab @click="editNewOp()" color="#005f32">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex
                  xs12
                  align-self-center
                  v-if="editingitem.options"
                  pa-2
                  px-5
                >
                  <v-layout
                    wrap
                    v-for="(esub, es) in editingitem.options"
                    :key="es"
                  >
                    <v-flex xs11 align-self-center text-left pa-2>
                      <v-text-field
                        v-model="esub.optionvalue"
                        label="Enter option"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs1 align-self-center text-left pa-2>
                      <v-btn
                        color="#000"
                        @click.prevent="editingitem.options.splice(es, 1)"
                        dark
                        small
                        fab
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  tile
                  outlined
                  @click="(editdialog = false), getData()"
                >
                  Close
                </v-btn>
                &nbsp;
                <v-btn
                  v-if="!g"
                  color="#005f32"
                  dark
                  tile
                  @click="validateQnEdit(editingitem._id)"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      questions: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      answerType: null,
      answerTypes: [
        {
          name: "Text",
          val: "text",
        },
        {
          name: "Dropdown",
          val: "dropdown",
        },
        // {
        //   name: "Checkbox",
        //   val: "checkbox",
        // },
      ],
      question: null,
      options: [],
      isRequired: false,
      order: null,
      isMultiSelect: false,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    answerType() {
      this.options = [];
    },
    options() {
      console.log("Op=", this.options);
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    addNewOp() {
      var optionval = {
        optionvalue: "",
      };
      this.options.push(optionval);
    },
    editNewOp() {
      var optionvale = {
        optionvalue: "",
      };
      this.editingitem.options.push(optionvale);
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },
    initialize() {
      this.admin = [];
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/exitInterviewQuestions/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/exitInterviewQuestions/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.questions = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
    validateQn() {
      if (!this.question) {
        this.msg = "Please provide a question";
        this.showsnackbar = true;
        return;
      } else if (!this.answerType) {
        this.msg = "Please choose an answer type";
        this.showsnackbar = true;
        return;
      } else if (
        this.answerType &&
        this.answerType != "text" &&
        this.options.length <= 0
      ) {
        this.msg = "Please provide options";
        this.showsnackbar = true;
        return;
      } else {
        this.addQuestion();
      }
    },

    addQuestion() {
      var data = {};
      data["question"] = this.question;
      data["answerType"] = this.answerType;
      data["options"] = this.options;
      data["order"] = this.order;
      data["isRequired"] = this.isRequired;
      data["isMultiSelect"] = this.isMultiSelect;
      axios({
        url: "/exitInterviewQuestions/add/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.question = null;
            this.options = [];
            this.answerType = null;
            this.order = null;
            this.isRequired = false;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    validateQnEdit() {
      if (!this.editingitem.question) {
        this.msg = "Please provide a question";
        this.showsnackbar = true;
        return;
      } else if (!this.editingitem.answerType) {
        this.msg = "Please choose an answer type";
        this.showsnackbar = true;
        return;
      } else if (
        this.editingitem.answerType &&
        this.editingitem.answerType != "text" &&
        this.editingitem.options.length <= 0
      ) {
        this.msg = "Please provide options";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["question"] = this.editingitem.question;
      data["answerType"] = this.editingitem.answerType;
      data["options"] = this.editingitem.options;
      data["order"] = this.editingitem.order;
      data["isRequired"] = this.editingitem.isRequired;
      data["isMultiSelect"] = this.editingitem.isMultiSelect;
      axios({
        url: "/exitInterviewQuestions/add/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
